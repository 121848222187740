/* .container {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
} */

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 50px;
  width: 50px;
  /* padding-top: 15%; */
}

.peg {
  margin: 0 auto;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.black {
  background-color: black;
}

.white {
  background-color: white;
}

.empty {
  background: linear-gradient(135deg,rgba(0,0,0,0.4),rgba(255,255,255,0.2));
}

.upperLeft {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.upperRight {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.bottomLeft {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.bottomRight {
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}

.middle {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}
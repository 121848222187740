@media only screen and (min-width: 740px) {

  .bigContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .codemaker {
    font-size: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }

  .container {
    margin-top: 30px;
    padding-top: 10px;
    font-size: 2em;
    height: 400px;
    width: 50vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
    background-repeat: no-repeat;
    background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
    background-size: cover;
    border-radius: 38.5px;
    box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
    border: 1px solid rgb(65, 38, 15);
  }

  .secretCode {
    grid-column: 3 / 11;
    grid-row: 2 / 3;
  }

  .colorOptions {
    margin-top: 30px;
    grid-column: 2 / 12;
    grid-row: 3 / 4;
  }

  .buttons {
    grid-column: 2 / 12;
    grid-row: 4 / 9;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;
  }

  .incompleteCode {
    border-radius: 10px;
    background-color: rgb(228, 198, 162);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgb(65, 38, 15);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
  }

  .submitCode {
    border-radius: 10px;
    background-color: rgb(65, 38, 15);
    color: rgb(228, 198, 162);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgb(228, 198, 162);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    cursor: pointer;
  }

  .randomCode {
    border-radius: 10px;
    background-color: rgb(228, 198, 162);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgb(65, 38, 15);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    cursor: pointer;
  }

  .randomCode:hover {
    background-color: rgb(220, 183, 139);
  }
}

@media only screen and (max-width: 740px) {

  .bigContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .codemaker {
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .container {
    margin-top: 30px;
    padding-top: 10px;
    font-size: 1.5em;
    height: 400px;
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
    background-repeat: no-repeat;
    background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
    background-size: cover;
    border-radius: 38.5px;
    box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
    border: 1px solid rgb(65, 38, 15);
  }

  .secretCode {
    grid-column: 1 / 13;
    grid-row: 2 / 3;
  }

  .colorOptions {
    margin-top: 30px;
    grid-column: 1 / 13;
    grid-row: 3 / 4;
  }

  .buttons {
    grid-column: 2 / 12;
    grid-row: 4 / 9;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 10px;
    text-align: center;
  }

  .incompleteCode {
    border-radius: 10px;
    background-color: rgb(228, 198, 162);
    padding: 10px;
    border-radius: 20px;
    border: 1px solid rgb(65, 38, 15);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    margin-right: 5px;
  }

  .submitCode {
    border-radius: 10px;
    background-color: rgb(65, 38, 15);
    color: rgb(228, 198, 162);
    padding: 10px;
    border-radius: 20px;
    border: 1px solid rgb(228, 198, 162);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    cursor: pointer;
    margin-right: 5px;
  }

  .randomCode {
    margin-left: 5px;
    border-radius: 10px;
    background-color: rgb(228, 198, 162);
    padding: 10px;
    border-radius: 20px;
    border: 1px solid rgb(65, 38, 15);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    cursor: pointer;
  }

  .randomCode:hover {
    background-color: rgb(220, 183, 139);
  }
}
.circle {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.r {
  background: radial-gradient(circle at 30% 30%, rgb(255, 60, 0) 0px, rgb(254, 6, 6) 3%, rgb(157, 0, 0) 50%, rgb(255, 0, 0) 100%); 
}

.b {
  /* background: -webkit-radial-gradient(65% 15%, circle, white 1px, aqua 3%, darkblue 60%, aqua 100%); 
  background: -moz-radial-gradient(65% 15%, circle, white 1px, aqua 3%, darkblue 60%, aqua 100%); 
  background: -o-radial-gradient(65% 15%, circle, white 1px, aqua 3%, darkblue 60%, aqua 100%); */
  background: radial-gradient(circle at 30% 30%, rgb(0, 123, 255) 0px, rgb(0, 115, 255) 3%, darkblue 50%, rgb(0, 0, 255) 100%); 
  /* background: radial-gradient(circle at 18px 18px, rgb(0, 60, 255), #000); */
}

.g {
  background: radial-gradient(circle at 30% 30%, rgb(0, 150, 0) 0px, rgb(0, 184, 0) 3%, darkgreen 50%, darkgreen 100%); 
}

.y {
  background: radial-gradient(circle at 30% 30%, yellow 0px, rgb(230, 230, 0) 3%, rgb(172, 158, 0) 50%, rgb(187, 187, 0) 100%); 

}

.o {
  background: radial-gradient(circle at 30% 30%, orange 0px, rgb(255, 153, 28) 3%, rgb(169, 110, 0) 50%, rgb(210, 115, 0) 100%); 
}

.p {
  background: radial-gradient(circle at 30% 30%, rgb(235, 14, 255) 0px, rgb(234, 0, 255) 3%, purple 50%, rgb(234, 0, 255) 100%); 
}

.n {
  background: radial-gradient(circle at 30% 30%, brown 0px, rgb(184, 47, 47) 3%, rgb(89, 23, 23) 50%, brown 100%); 
}

.w {
  background: radial-gradient(circle at 30% 30%, white 0px, white 3%, rgb(160, 160, 160) 50%, rgb(169, 169, 169) 100%); 
}

.x {
  background: linear-gradient(135deg,rgba(0,0,0,0.4),rgba(255,255,255,0.2));
}

.maybe {
  background: linear-gradient(135deg,rgba(0,0,0,0.4),rgba(255,255,255,0.2));
}

.impossible {
  background: radial-gradient(circle at 90% 90%, rgb(80, 80, 80) 0px, rgb(40, 39, 39) 3%, rgb(55, 55, 55) 50%, black 100%); 
  /* background: linear-gradient(135deg,rgba(0,0,0),rgb(70, 70, 70)); */
}

.certain {
  box-shadow:
  inset 0 0 8px #fff,
  inset 3px 0 13px rgb(138, 118, 2),
  inset -3px 0 13px yellow,
  inset 3px 0 50px gold,
  inset -3px 0 50px yellow,
  0 0 1px #fff,
  -2px 0 13px gold,
  2px 0 13px yellow;
  -webkit-animation: glowing 3000ms infinite;
  -moz-animation: glowing 3000ms infinite;
  -o-animation: glowing 3000ms infinite;
  animation: glowing 3000ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
  50% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 15px rgb(228, 198, 162);
  }
  100% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
}
@keyframes glowing {
  0% {
    background-color: yellow;
    box-shadow:
    inset 0 0 8px #fff,
    inset 3px 0 13px rgb(138, 118, 2),
    inset -3px 0 13px yellow,
    inset 3px 0 50px gold,
    inset -3px 0 50px yellow,
    0 0 1px #fff,
    -2px 0 3px gold,
    2px 0 3px yellow;
  }
  50% {
    background-color: rgb(220, 183, 139);
    box-shadow:
    inset 0 0 8px #fff,
    inset 3px 0 13px rgb(138, 118, 2),
    inset -3px 0 13px yellow,
    inset 3px 0 50px gold,
    inset -3px 0 50px yellow,
    0 0 1px #fff,
    -2px 0 13px gold,
    2px 0 13px yellow;
  }
  100% {
    background-color: yellow;
    box-shadow:
    inset 0 0 8px #fff,
    inset 3px 0 13px rgb(138, 118, 2),
    inset -3px 0 13px yellow,
    inset 3px 0 50px gold,
    inset -3px 0 50px yellow,
    0 0 1px #fff,
    -2px 0 3px gold,
    2px 0 3px yellow;
  }
}
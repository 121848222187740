
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 6em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.fadeIn {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

ul {
  padding: 0;
  font-size: 3em;
  height: 55vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
  background-repeat: no-repeat;
  background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
  background-size: cover;
  border-radius: 38.5px;
  box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
  border: 1px solid rgb(65, 38, 15);
}

.fadeInSlow {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}

@keyframes fadeInSlow {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

ul li {
  list-style: none;
  border: 1px solid rgb(65, 38, 15);
  padding: 1vh 2vw;
  background-color: rgb(228, 198, 162);
  border-radius: 20px;
  margin: 2vh;
  text-align: center;
}

ul li:hover {
  color: rgb(228, 198, 162);
  background-color: rgb(65, 38, 15);
  border: 1px solid rgb(228, 198, 162);
  cursor: pointer;
}

.algo:hover {
  color: rgb(65, 38, 15);
  cursor: pointer;
  border: 1px solid rgb(65, 38, 15);
}

span {
  display: block;
  font-size: 0.5em;
}

.colors {
  width: 45vw;
}

.glowing {
  -webkit-animation: glowing 1250ms infinite;
  -moz-animation: glowing 1250ms infinite;
  -o-animation: glowing 1250ms infinite;
  animation: glowing 1250ms infinite;
}

p {
  text-align: center;
  font-size: 2em;
  margin: 0;
  padding-top: 2px;
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
  50% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 15px rgb(228, 198, 162);
  }
  100% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
}
@keyframes glowing {
  0% {
    background-color: rgb(228, 198, 162);
    box-shadow: 0 0 3px rgb(228, 198, 162);
  }
  50% {
    background-color: rgb(227, 184, 133);
    box-shadow: 0 0 15px rgb(228, 198, 162);
  }
  100% {
    background-color: rgb(228, 198, 162);
    box-shadow: 0 0 3px rgb(228, 198, 162);
  }
}


@media only screen and (max-width: 740px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .colors {
    width: 98vw;
  }

  ul {
    padding: 0;
    font-size: 2.5em;
    height: 55vh;
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
    background-repeat: no-repeat;
    background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
    background-size: cover;
    border-radius: 38.5px;
    box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
    border: 1px solid rgb(65, 38, 15);
  }

  ul li {
    text-align: center;
    list-style: none;
    border: 1px solid rgb(65, 38, 15);
    padding: 1vh 2vw;
    background-color: rgb(228, 198, 162);
    border-radius: 20px;
    margin: 2vh;
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
  }

  p {
    padding-top: 9px;
    font-size: 1em;
  }
}
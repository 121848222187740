.title {
  font-size: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.container {
  margin: 30px auto;
  padding-top: 18px;
  font-size: 2em;
  height: 75vh;
  width: 45vw;
  min-width: 480px;
  max-width: 520px;
  min-height: 520px;
  max-height: 600px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
  background-repeat: no-repeat;
  background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
  background-size: cover;
  border-radius: 38.5px;
  box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
  border: 1px solid rgb(65, 38, 15);
}

.left {
  grid-column: 1 / 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right {
  grid-column: 4 / 9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.startGame {
  grid-column: 3 / 7;
  grid-row: 7 / 8;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(228, 198, 162);
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgb(65, 38, 15);
  box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
}

.startGame:hover {
  color: rgb(228, 198, 162);
  background-color: rgb(65, 38, 15);
  border: 1px solid rgb(228, 198, 162);
  cursor: pointer;
}

@media only screen and (max-width: 740px) {
  .title {
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    margin: 30px auto;
    padding-top: 18px;
    font-size: 1.5em;
    height: 70vh;
    width: 90vw;
    min-width: 370px;
    min-height: 400px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
    background-repeat: no-repeat;
    background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
    background-size: cover;
    border-radius: 38.5px;
    box-shadow: 10px 10px 20px rgba(65, 38, 15, 0.6);
    border: 1px solid rgb(65, 38, 15);
  }

  .left {
    grid-column: 1 / 4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
  }
  
  .right {
    grid-column: 4 / 9;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;
  }

  .startGame {
    grid-column: 2 / 8;
    grid-row: 7 / 8;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(228, 198, 162);
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgb(65, 38, 15);
    box-shadow: 5px 5px 10px rgba(65, 38, 15, 0.6);
    font-size: 1.5em;
  }
  
  .startGame:hover {
    color: rgb(228, 198, 162);
    background-color: rgb(65, 38, 15);
    border: 1px solid rgb(228, 198, 162);
    cursor: pointer;
  }
}
.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  margin-top: 10px;
}

.colorTracker {
  grid-column: 1 / 6;
  grid-row: 1 / 13;
}

.consoleCenter {
  grid-column: 6 / 8;
  grid-row: 4 / 10;
}

.consoleLeft {
  grid-column: 2 / 4;
  grid-row: 4 / 10;
}

.boardRight {
  grid-column: 8 / 13;
  grid-row: 1 / 13;
}

.boardCenter {
  grid-column: 4 / 10;
  grid-row: 1 / 13;
}

.makeCode {
  grid-column: 4 / 10;
  grid-row: 1 / 13;
}

.boardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(24, 1fr);
  width: 500px;
  height: 98vh;
  min-height: 810px;
  margin: auto;
  background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
  background-repeat: no-repeat;
  background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
  background-size: cover;
  border-radius: 38.5px;
  background-position: right;
  box-shadow: -60px 0px 100px -90px #000000, 60px 0px 100px -90px #000000;
  border: 1px solid rgb(65, 38, 15);
}

.fadeIn {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.secretCode {
  grid-column: 1 / 6;
  grid-row: 1 / 3;
}

.turns {
  grid-column: 1 / 6;
  grid-row: 3 / 23;
  margin-top: 10px;
  margin-bottom: 10px;
}

.blackAndWhitePegs {
  grid-column: 5 / 6;
  grid-row: 4 / 22;
}

.colorOptions {
  grid-column: 1 / 6;
  grid-row: 23 / 25;
}





/* ***************************** MOBILE ***************************** */

@media only screen and (max-width: 740px) {
  .mobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }

  .consoleMobile {
    width: 100%;
  }

  .boardContainerMobile {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(24, 1fr);
    width: 100vw;
    height: 99vh;
    margin: auto;
    background-image: url("https://cdn.wallpapersafari.com/84/55/u9cdnI.jpg");
    background-repeat: no-repeat;
    background-color: rgb(218, 170, 108); /* Used if the image is unavailable */
    background-size: cover;
    border-radius: 38.5px;
    background-position: right;
    box-shadow: -60px 0px 100px -90px #000000, 60px 0px 100px -90px #000000;
    border: 1px solid rgb(65, 38, 15);
  }

  .colorTracker {
    width: 100vw;
    height: 100vh;
  }
 
  .consoleCenter {
    grid-column: 6 / 8;
    grid-row: 4 / 10;
  }
  
  .consoleLeft {
    grid-column: 2 / 4;
    grid-row: 4 / 10;
  }
  
  .boardRight {
    grid-column: 8 / 13;
    grid-row: 1 / 13;
  }
  
  .boardCenter {
    grid-column: 4 / 10;
    grid-row: 1 / 13;
  }
  
  .makeCode {
    grid-column: 4 / 10;
    grid-row: 1 / 13;
  }

  
  .fadeIn {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  
  .secretCode {
    grid-column: 1 / 6;
    grid-row: 1 / 3;
  }
  
  .turns {
    grid-column: 1 / 6;
    grid-row: 3 / 23;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .blackAndWhitePegs {
    grid-column: 5 / 6;
    grid-row: 4 / 22;
  }
  
  .colorOptions {
    grid-column: 1 / 6;
    grid-row: 23 / 25;
  }
}
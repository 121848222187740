.rowContainer {
  display: flex;
  height: 100%;
}

.currentTurn {
  background-color: rgba(255, 255, 255, 0.33);
  opacity: 0.9;
}

.guess {
  width: 83%;
  height: 100%;
}

.bwPegs {
  display: flex;
  height: 100%;
  width: 17%;
  justify-content: center;
  align-items: center;
}

.submitGuessButton {
  color: rgb(228, 198, 162);
  background-color: rgb(65, 38, 15);
  box-shadow: -5px 5px 10px rgba(65, 38, 15, 0.6);
  width: 17%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px 0 0 10px;
  font-size: 3em;
}
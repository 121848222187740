.container {
  display: flex;
  height: 100%;
}

.secretCode {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 83%;
  margin-top: 10px;
}

.currentTurn {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  width: 17%;
  border-radius: 0 20px 0 0;
}

.text {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}
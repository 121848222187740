.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
}

.column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color {
  grid-column: 1 / 3;
}

.number {
  grid-column: 3 / 5;
  margin-left: -10px;
  margin-bottom: 8px;
}

.position {
  grid-column: 5 / 13;
  margin-right: -10px;
  margin-left: -20px;
}

@media only screen and (max-width: 740px) {
  .number {
    grid-column: 3 / 5;
    margin-left: -20px;
    margin-bottom: 8px;
    font-size: 0.75em;
  }

  .position {
    grid-column: 5 / 13;
    margin-right: 0px;
    margin-left: -20px;
  }
}
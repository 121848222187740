html, body {
  background-image: linear-gradient(rgb(238, 209, 173), rgb(181, 155, 122));
  font-family: 'Radley', serif;
  color: rgb(65, 38, 15);
  margin: 0;
  height: 100%;
}

@media only screen and (max-width: 740px) {
  html, body {
   background-image: linear-gradient(rgb(238, 209, 173), rgb(238, 209, 173));
  }
}

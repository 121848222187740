.container {
  /* height: 100%; */
  padding-top: 10px;
  width: 100%;
  background-color: rgb(65, 38, 15);
  color: rgb(228, 198, 162);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.round {
  font-size: 2em;
  width: 80%;
  border-bottom: 1px solid rgb(228, 198, 162);
  text-align: center;
}

.scores {
  width: 100%;
  font-size: 2em;
  display: flex;
  margin-top: 10px;
}

.score {
  width: 50%;
  display: flex;
  justify-content: center;
}

.names {
  width: 100%;
  font-size: 1.5em;
  display: flex;
}

.name {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: 2%;
}

.codebreaker {
  color: rgb(65, 38, 15);
  background-color: rgb(228, 198, 162);
  border-radius: 10px;
}

.nextComputerGuess {
  text-align: center;
  font-size: 1.5em;
  margin-top: 30px;
  color: rgb(65, 38, 15);
  background-color: rgb(228, 198, 162);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.glowing {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
  50% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 15px rgb(228, 198, 162);
  }
  100% {
    background-color: rgb(228, 198, 162);
    -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
  }
}
@keyframes glowing {
  0% {
    background-color: rgb(228, 198, 162);
    box-shadow: 0 0 3px rgb(228, 198, 162);
  }
  50% {
    background-color: rgb(220, 183, 139);
    box-shadow: 0 0 15px rgb(228, 198, 162);
  }
  100% {
    background-color: rgb(228, 198, 162);
    box-shadow: 0 0 3px rgb(228, 198, 162);
  }
}

.whoScored {
  font-size: 1.5em;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.switchRoles {
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  margin-top: 20px;
  color: rgb(65, 38, 15);
  background-color: rgb(228, 198, 162);
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.gameOver {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  margin-top: 20px;
}

.playAgain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: rgb(65, 38, 15);
  background-color: rgb(228, 198, 162);
  border-radius: 10px;
  padding: 10px;
}

.toggleOptions {
  margin-top: 40px;
  padding-bottom: 5px;
  cursor: pointer;
  width: 80%;
  text-align: center;
}

.underline {
  border-bottom: 1px solid rgb(228, 198, 162);
}

/* .toggleColorTracker {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
}

.restartGame {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
  margin-bottom: 10px;
} */

.option {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  cursor: pointer;
}

/* ************************************************ MOBILE ************************************************ */

@media only screen and (max-width: 740px) {
  .container {
    margin: 20px 0;
    width: 100vw;
    background-color: rgb(65, 38, 15);
    color: rgb(228, 198, 162);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .round {
    font-size: 1.5em;
    width: 80%;
    border-bottom: 1px solid rgb(228, 198, 162);
    text-align: center;
  }
  
  .scores {
    width: 100%;
    font-size: 2em;
    display: flex;
    margin-top: 0px;
  }
  
  .score {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  
  .names {
    width: 100%;
    font-size: 1.5em;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .name {
    width: 50%;
    display: flex;
    justify-content: center;
  }
  
  .codebreaker {
    color: rgb(65, 38, 15);
    background-color: rgb(228, 198, 162);
    border-radius: 10px;
  }
  
  .nextComputerGuess {
    text-align: center;
    font-size: 1.5em;
    margin-top: 30px;
    color: rgb(65, 38, 15);
    background-color: rgb(228, 198, 162);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }
  
  .glowing {
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
  }
  
  @-webkit-keyframes glowing {
    0% {
      background-color: rgb(228, 198, 162);
      -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
    }
    50% {
      background-color: rgb(228, 198, 162);
      -webkit-box-shadow: 0 0 15px rgb(228, 198, 162);
    }
    100% {
      background-color: rgb(228, 198, 162);
      -webkit-box-shadow: 0 0 3px rgb(228, 198, 162);
    }
  }
  @keyframes glowing {
    0% {
      background-color: rgb(228, 198, 162);
      box-shadow: 0 0 3px rgb(228, 198, 162);
    }
    50% {
      background-color: rgb(220, 183, 139);
      box-shadow: 0 0 15px rgb(228, 198, 162);
    }
    100% {
      background-color: rgb(228, 198, 162);
      box-shadow: 0 0 3px rgb(228, 198, 162);
    }
  }
  
  .whoScored {
    font-size: 1.5em;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .switchRoles {
    display: flex;
    justify-content: center;
    font-size: 1.5em;
    margin-top: 20px;
    color: rgb(65, 38, 15);
    background-color: rgb(228, 198, 162);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }
  
  .gameOver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .playAgain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: rgb(65, 38, 15);
    background-color: rgb(228, 198, 162);
    border-radius: 10px;
    padding: 10px;
  }
  
  .toggleOptions {
    margin-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
    width: 80%;
    text-align: center;
  }
  
  .underline {
    border-bottom: 1px solid rgb(228, 198, 162);
  }
  
  .toggleColorTracker {
    margin-top: 15px;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .restartGame {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    cursor: pointer;
    margin-bottom: 10px;
  }
}